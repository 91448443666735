.condition-group-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    cursor: grab;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
}



.condition-group {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    min-width: 600px;
    position: relative;
}
