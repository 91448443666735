
.chart-details-text {
    cursor: pointer;
}

.chart-details-text:hover {
    border-bottom: 1px solid black;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}
