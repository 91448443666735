
.condition-selector-input {
    border-radius: 4px;

}

.condition-selector-input .MuiOutlinedInput-notchedOutline {
    border: none;
}

.MuiAutocomplete-listbox {
    background: #3C3F41
}

.condition-selector-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #00a1ff;
}

.condition-selector-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #00a1ff;
}
