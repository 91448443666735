.mainLayout {
    display: flex;
    height: calc(100vh - 16px);
    padding: 8px;
    overflow: hidden;
}

.mainLayout .activeTab {
    display: block;
    height: 100%;
}

.mainLayout .inactiveTab {
    display: none;
}

.side-nav-icon {
    transition: transform 0.3s ease-in-out;
    z-index: 102;
}

.side-nav-icon.active {
    background-color: rgba(0, 161, 255, 0.3);
}

/* This will target the icon itself when the button is hovered */
.side-nav-icon:hover > * {
    transform: scale(1.05); /* Scale up the icon */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
}
