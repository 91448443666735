.condition-header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    cursor: grab;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.condition-setting-icon {
    cursor: pointer;
    padding: 2px;
    border-radius: 8px;
}

.condition-setting-icon.default {
    color: lightgray;
    border: 1px dotted lightgray;
}

.condition-setting-icon.active {
    color: rgba(0, 161, 255, .8);
    border: 1px solid rgba(0, 161, 255, .8);
}

.condition-setting-icon:hover {
    border: 1px solid rgba(0, 161, 255, .8);
}
