.table-fade {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.table-fade::before,
.table-fade::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    z-index: 1;
}

.no-left-fade::before {
    display: none;
}

.no-right-fade::after {
    display: none;
}
