
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; /* Ensure it overlays the children */
    pointer-events: none;
}

.disabled * {
    pointer-events: none;
}
