.fade-in {
    animation-name: fadeInAndMove;
    animation-duration: 1s;
}

@keyframes fadeInAndMove {
    from {
        opacity: 0;
        transform: translateY(200px);  /* Start at the original position */
    }
    to {
        opacity: 1;
        transform: translateY(0);  /* Start at the original position */
    }
}

.fade-out {
    animation-name: fadeOutAndMove;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: .25s;
}

@keyframes fadeOutAndMove {
    from {
        opacity: 1;
        transform: translateY(0);  /* Start at the original position */
    }
    to {
        opacity: 0;
        transform: translateY(200px);
    }
}

.ad-card-header {
    font-weight: 700 !important;

    /* Gradient Background */
    background: linear-gradient(180deg, #18BB0E, rgba(0,128,0,.3));

    /* Applying the Gradient to Text */
    background-clip: text;
    -webkit-background-clip: text; /* For Safari compatibility */
    color: transparent;
}


.ad-primary-text-color {
    color: #18BB0E;
}
